import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { LoadingProvider } from "./context/LoadingContext";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store'
import './custom.scss';

ReactDOM.render(
  <Provider store={ store }>
    <LoadingProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LoadingProvider>
  </Provider>,
  document.getElementById('root')
);