import React from 'react';
import Container from 'react-bootstrap/Container';
import { API_URL } from '../../utils/consts';
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'

import { LightgalleryProvider, withLightgallery, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

const OpenButtonWithHoc = withLightgallery(({ openGallery, ...props }) => {
  return (
    <Button 
      onClick={() => {
        openGallery("featured-images");
      }}
      className="d-flex align-items-center justify-content-center circle-icon"
    >
      <i className='bx bx-camera'></i>
    </Button>
  );
});

function Featured({ images }) {
  return (
    <Container fluid className='p-0 w-100 featured-carousel'>
      <LightgalleryProvider galleryClassName="featured-images">
        <Carousel 
          fade 
          interval={ 5000 } 
          indicators={false} 
          className="carousel-backdrop"
          prevIcon={<i className="bx bx-chevron-left"></i>}
          nextIcon={<i className="bx bx-chevron-right"></i>}
        >
          { images && images.images.data && images.images.data.map((item, key) => (
            <Carousel.Item key={key} className="image-backdrop">
              <LightgalleryItem 
                group='featured-images' 
                src={ API_URL + item.attributes.url }
                thumb={ API_URL + item.attributes.url }
              >
                <img
                  className="d-block w-100"
                  src={ API_URL + item.attributes.url }
                  alt="First slide"
                />
              </LightgalleryItem>
              <Carousel.Caption>
                { images.title && <h1>{ images.title }</h1> }
                { images.description && <p className='text-prewrap'>{ images.description }</p> }
              </Carousel.Caption>
            </Carousel.Item>
          )) }
        </Carousel>
        <OpenButtonWithHoc />
      </LightgalleryProvider>

    
    </Container>
  );
}

export default Featured;