import axios from 'axios';
import { API_URL } from './consts'

export const api = axios.create({
  baseURL: API_URL + '/api',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use((config) => {
  config.headers['Authorization'] = 'Bearer 3a8c2e138c6dd8bc978bb3835304fe2345696ea0bcfa1ddce6fde9c590a30b5e98bf0bdb40ac438e6fcfb1d9bd9894d7608a7b0b63553dcb415bf1a747f34632c04232387a412888cfd2ccdc1408d340d8b5348981a0f144a4ce4eb5859ce932659b00bab545fd9131c60702c5a76c75ee8a0795048bca1652788ecbc6f33062';
  return config;
});

export default api;