import React, { useEffect } from 'react';
import SecondaryBanner from '../components/SecondaryBanner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Info from '../components/Projects/Info'
import Heading from '../components/Heading'
import ContactForm from '../components/Contact/ContactForm';
import { useSelector } from 'react-redux'
import { useLoading } from "../context/LoadingContext";

function Contact(props) {
  const { updateLoading } = useLoading();
  const contact = useSelector(state => state.contact?.payload)
  
  useEffect(() => {
    if(contact){
      updateLoading(false);
    }
  }, [contact])

  return (
    <>
      <SecondaryBanner />
      <Container className='my-5'>
        <Heading white_txt="Contact" orange_txt="us" />
        <Row className='flex-wrap single-project'>
          <Col sm md={6} lg="4">
            <div className='desc info'>
              <p className='mb-4'><span>BetA Architects</span></p>
              <p>{ contact?.about }</p>
              { contact?.vat && <Info label="VAT" value={ contact.vat } /> }
            </div>
          </Col>
          <Col sm md={6} lg="4">
            <div className='desc info'>
              <p className='mb-4'><span>Contact Details</span></p>
              <Info label="Phone" value={ contact?.phone } />
              <Info label="Email" value={ contact?.mail } />
              <Info label="Address" value={ contact?.address } />
            </div>
          </Col>
          <Col sm md={12} lg="4" className='border-0 contact-form'>
            <div className='info'>
              <p className='mb-4'><span>Contact Form</span></p>
              <ContactForm />
            </div>
          </Col>
        </Row>
        <Row className='my-5'>
          <Col>
            <iframe 
              title="Our Location"
              width="100%" 
              frameBorder="0" 
              scrolling="no" 
              marginHeight="0" 
              marginWidth="0" 
              className='map-container'
              style={{ filter: 'invert(90%)' }}
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=42.646502,%2021.218921+(BetA%20Architects)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;