import React from 'react';
import Col from 'react-bootstrap/Col';
import { LightgalleryItem } from "react-lightgallery";
import { API_URL } from '../../utils/consts'

function Image({ group, item }) {
  const { attributes } = item;
  return (
    <>
      <Col sm md={6} lg="6">
        <div className='item'>
          <LightgalleryItem 
            group={ group } 
            src={ API_URL + attributes.url }
            thumb={ API_URL + attributes.url }
          >
            <img src={ API_URL + attributes.url } alt={ attributes.name } />
          </LightgalleryItem>
        </div>
      </Col>
    </>
  );
}

export default Image;