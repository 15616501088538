import React from 'react';
import OurServices from '../components/Services/OurServices'
import SecondaryBanner from '../components/SecondaryBanner'

function Services(props) {
  return (
    <>
      <SecondaryBanner />
      <OurServices show={20} />
    </>
  );
}

export default Services;