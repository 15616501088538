import React, { useEffect, useState } from 'react';
import SecondaryBanner from '../components/SecondaryBanner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useParams } from 'react-router-dom'
import { LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import api from '../utils/api'
import qs from 'qs';
import Heading from '../components/Heading'
import Info from '../components/Projects/Info'
import Image from '../components/Projects/Image';
import next_and_previous from '../utils/previous_next';
import { useLoading } from "../context/LoadingContext";

function Project(props) {
  const { project_id } = useParams();
  const { updateLoading } = useLoading();
  const [project, setProject] = useState();
  const [previousNext, setPreviousNext] = useState();

  useEffect(() => {
    const query = qs.stringify({
      populate: '*'
    }, { encodeValuesOnly: true })
    const query2 = qs.stringify({
      fields: ['id']
    }, { encodeValuesOnly: true })

    Promise.all([
      api.get(`/projects/${ project_id }?${ query }`),
      api.get(`/projects?${ query2 }`)
    ]).then((values) => {
      setProject(values[0].data.data)
      setPreviousNext(next_and_previous(values[1].data.data, project_id))
      updateLoading(false);
    });
  }, [project_id])

  return (
    <>
      <SecondaryBanner />
      <Container className='mt-5'>
        <Heading white_txt="" orange_txt={ project?.attributes?.name } />
        <Row className='flex-wrap single-project'>
          <Col sm md={8} lg="8">
            <div className='desc text-prewrap'>
              { project?.attributes?.description }
            </div>
          </Col>
          <Col sm md={4} lg="4" className='border-0'>
            <div className='info'>
              { project?.attributes?.year && <Info label="Year" value={ project?.attributes?.year } /> }
              { project?.attributes?.client && <Info label="Company" value={ project?.attributes?.client } /> }
              { project?.attributes?.project_name && <Info label="Project Name" value={ project?.attributes?.project_name } /> }
              { project?.attributes?.location && <Info label="Location" value={ project?.attributes?.location } /> }
            </div>
          </Col>
        </Row>
        <LightgalleryProvider galleryClassName="project-images">
          <Row className='flex-wrap projects images mt-5'>
            { project 
              && project.attributes.images.data
              && project.attributes.images.data.map((item, key) => <Image key={ key } item={ item } group="project-images" /> )}
          </Row>
        </LightgalleryProvider>
      </Container>

      <Container fluid>
        <Container className='controllers-mb-10'>
          <Row className='footer-last-row project-controllers'>
            <Col xs='5' className='footer-col border-0'>
              <Link to={previousNext && previousNext[0] ? `/projects/${previousNext[0]}` : '#'} className='value d-flex align-items-center'>
                <i className='bx bx-left-arrow-alt prev-next-size'></i> Previous Project
              </Link>
            </Col>
            <Col xs='2' className='footer-col justify-content-center border-0'>
              <Link to='/projects' className='value'><i className='bx bxs-grid go-back-size'></i></Link>
            </Col>
            <Col xs='5' className='footer-col d-flex justify-content-end border-0'>
              <Link to={previousNext && previousNext[1] ? `/projects/${previousNext[1]}` : '#'} className='value d-flex align-items-center'>
                Next Project <i className='bx bx-right-arrow-alt prev-next-size' ></i>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Project;