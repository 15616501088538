import React from 'react';
import Col from 'react-bootstrap/Col';

import CircleIcon from '../CircleIcon'
import { API_URL } from '../../utils/consts'

function Member({ item }) {
  const { attributes } = item;
  return (
    <>
      <Col sm lg="4">
        <div className='item'>
          <img src={ API_URL + attributes.image.data.attributes.url } alt="Service 1" />
          <div className='position-relative more-info py-2'>
            <h5 className='text-center'>{ attributes.full_name }</h5>
            <p className='text-center mb-0 pb-3'>{ attributes.position }</p>
            <div className='socials'>
              <p className='mb-1'>{ attributes.other }</p>
              <div className='d-flex position-relative socials-list'>
                <CircleIcon link={ attributes.facebook_url } className='bx bxl-facebook mx-3' />
                <CircleIcon link={ attributes.instagram_url } className='bx bxl-instagram mx-1' />
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}

export default Member;