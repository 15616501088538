const next_and_previous = (array, id) => {
  let previous = null;
  let next = null;
  if(Array.isArray(array) && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if(array[i].id === parseInt(id)){
        previous = array[i-1] ? array[i-1].id : null;
        next = array[i+1] ? array[i+1].id : null;
        break;
      }
    }
  }

  return [previous, next];
}

export default next_and_previous;