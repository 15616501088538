import React, { useEffect, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'

import { NavLink, useLocation } from 'react-router-dom'

function Header(props) {
  const { pathname } = useLocation();
  const [header, setHeader] = useState(false);   

  const handleChange = () => {
    if(typeof window !== 'undefined')
      setHeader(window.pageYOffset > 80 ? true : false); 
  }

  useEffect(() => {
    window.addEventListener("scroll", handleChange);
  
    return () => {
      window.removeEventListener("scroll", handleChange);
    };
  }, []);


  return (
    <Navbar collapseOnSelect={true} expand="lg" variant="dark" className={`p-0 ${header ? 'navbar-changed' : 'navbar-edited'}`}>
      <Container fluid className='top-header p-0'>
        <Navbar.Brand as={ NavLink } to="/" className='p-0'>
          <div className={`logo-container ${header && 'changed'} ${(pathname !== '/' && pathname !== '/home') && 'bg-transparent'}`}>
            <img src={ `/assets/images/logo.png` } alt="Logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav activeKey="/" className="ms-auto nav-custom">
            <Nav.Link eventKey="link-1" as={ NavLink } to="/">Home</Nav.Link>
            <Nav.Link eventKey="link-2" as={ NavLink } to="/about">About</Nav.Link>
            <Nav.Link eventKey="link-3" as={ NavLink } to="/services">Services</Nav.Link>
            <Nav.Link eventKey="link-4" as={ NavLink } to="/projects">Projects</Nav.Link>
            <Nav.Link eventKey="link-5" as={ NavLink } to="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;