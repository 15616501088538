import React, { useState } from 'react';
import { useFormik, Field, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import api from '../../utils/api'
import Alert from 'react-bootstrap/Alert';

function ContactForm(props) {
  const [alerts, setAlerts] = useState(null);

  const validation_schema = Yup.object({
    full_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    message: Yup.string().min(20, 'Must be 20 characters or less').required('Required')
  });

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      message: ''
    },
    validationSchema: validation_schema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("Clicked");
      api.post('/email', {
        "to": "info@betaarchitects.com",
        "from": 'arbenitiab@gmail.com',
        "replyTo": `${values.email}`,
        "subject": "New email from Website Contact Form",
        "text": `
          Full name: ${values.full_name} \n
          Email: ${values.email} \n
          Message: ${values.message}
        `
      }).then(res => {
        if(!res || res.status !== 200){
          setSubmitting(false); 
          setAlerts({ type: 'danger', msg: 'There was an error, please try again.' });
        }else {
          resetForm();
          setAlerts({ type: 'success', msg: 'Email sent successfully.' });
          setTimeout(() => {
            setAlerts(null)
          }, 5000)
        }
      })
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={ formik.handleSubmit }>
        { alerts && <Alert variant={ alerts.type }>{ alerts.msg }</Alert> }
        <Field
          type="text"
          name="full_name"
          placeholder='Full name'
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          value={ formik.values.full_name }
        />
        { formik.touched.full_name && formik.errors.full_name ? (
          <span className='required'>{formik.errors.full_name}</span>
        ) : null}

        <Field
          name="email"
          type="email"
          placeholder='Your Email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <span className='required'>{formik.errors.email}</span>
        ) : null}

        <Field
          type="text"
          name="message"
          as="textarea"
          placeholder='Your Message'
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          value={ formik.values.message }
        />
        {formik.touched.message && formik.errors.message ? (
          <span className='required'>{formik.errors.message}</span>
        ) : null}
        
        <button className='btn-new mt-2' type="submit" disabled={ formik.isSubmitting }>
          Submit
        </button>
      </Form>
    </FormikProvider>
  );
}

export default ContactForm;