import qs from 'qs';
import React, { useEffect, useState } from 'react';
import SecondaryBanner from '../components/SecondaryBanner'
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useParams } from 'react-router-dom'
import Heading from '../components/Heading'
import api from '../utils/api'
import Image from '../components/Projects/Image'
import { LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import { useLoading } from "../context/LoadingContext";

function Service(props) {
  const { service_id } = useParams();
  const { updateLoading } = useLoading();
  const [service, setService] = useState({});
  const [services, setServices] = useState();

  useEffect(() => {
    const query = qs.stringify({
      populate: '*'
    }, { encodeValuesOnly: true });
    const query2 = qs.stringify({
      fields: ['name']
    }, { encodeValuesOnly: true });
    
    Promise.all([
      api.get(`/services/${ service_id }?${query}`),
      api.get(`/services?${query2}`)
    ]).then((values) => {
      setService(values[0].data.data)
      setServices(values[1].data.data)
      updateLoading(false);
    });
  }, [service_id])

  const ListItem = ({ item }) => (
    <ListGroup.Item 
      as={ Link } 
      to={`/services/${item.id}`}
      className={`d-flex justify-content-between align-items-center ${ item.id === parseInt(service_id) && 'active' }`}
    >
      <span>{ item.attributes.name }</span>
      <i className='bx bx-chevron-right'></i>
    </ListGroup.Item>
  );

  return (
    <>
      <SecondaryBanner />
      <Container className='mt-5'>
        <Row className='flex-wrap single-project'>
          <Col xs md={12} lg="8">
            <Row className='flex-column'>
              <Col>
                <div className='desc'>
                  <Heading white_txt="" orange_txt={ service?.attributes?.name } />
                  <div className='text-prewrap'>
                    {service?.attributes?.description}
                  </div>
                </div>
              </Col>

              { service && service?.attributes?.images?.data 
                && <Col>
                    <LightgalleryProvider galleryClassName="project-images">
                      <Row className='flex-wrap projects images service-images mt-5'>
                        { service.attributes.images.data.map((item, key) => <Image key={ key } group='service-images' item={ item } remove_col={ true } /> ) }
                      </Row>
                    </LightgalleryProvider>
                  </Col>
              }
            </Row>
          </Col>
          <Col xs md={12} lg="4" className='border-0'>
            <div className="all-services mb-4">
              <h5>All services</h5>
              <ListGroup defaultActiveKey="#link1">
                { services && services.map((item, key) => (
                  <ListItem key={ key } item={ item } />
                )) }
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Service;