import React, { useEffect, useState } from 'react';
import SecondaryBanner from '../components/SecondaryBanner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Heading from '../components/Heading'
import Project from '../components/Projects/Project'
import api from '../utils/api'
import qs from 'qs';
import { useLoading } from "../context/LoadingContext";

function Projects(props) {
  const { updateLoading } = useLoading();
  const [projects, setProjects] = useState();

  useEffect(() => {
    const query = qs.stringify({
      populate: '*',
      sort: ['order_nr:asc'],
    }, { encodeValuesOnly: true })
    api.get(`/projects?${ query }`).then(res => {
      setProjects(res.data.data)
      updateLoading(false);
    })
  }, [])

  return (
    <>
      <SecondaryBanner />
      <Container className='mt-5'>
        <Heading white_txt="Our" orange_txt="projects" />
        <Row className='flex-wrap projects'>
          { projects && projects.map((item, key) => <Project key={ key } item={ item } />) }
        </Row>
      </Container>
    </>
  );
}

export default Projects;