import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'
import CircleIcon from './CircleIcon'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'

function Footer(props) {
  const contact = useSelector(state => state.contact?.payload)

  return !contact ? <Loader /> : (
    <Container fluid className='px-0'>
      <Container fluid style={{ borderBottom: '1px solid #323232', borderTop: '1px solid #323232'}}>
        <Container>
          <Row>
              <Col sm md={12} lg='4' className='footer-col'>
                <h6 className='label'>Phone</h6>
                <p className='value'>{ contact.phone }</p>
              </Col>
              <Col sm md={12} lg='4' className='footer-col'>
                <h6 className='label'>Email</h6>
                <p className='value'>{ contact.mail }</p>
              </Col>
              <Col sm md={12} lg='4' className='footer-col'>
                <h6 className='label'>Our Address</h6>
                <p className='value'>{ contact.address }</p>
              </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row className='footer-last-row'>
            <Col sm md={12} lg='4' className='footer-col'>
              <p className='value'>© Copyright 2022 BETA.</p>
            </Col>
            <Col sm md={12} lg='4' className='footer-col justify-content-center'>
              <div className='d-flex position-relative socials-list'>
                <CircleIcon link={ contact.facebook_url } className='bx bxl-facebook' />
                <CircleIcon link={ contact.instagram_url } className='bx bxl-instagram' />
              </div>
            </Col>
            <Col sm md={12} lg='4' className='footer-col d-flex justify-content-end'>
              <Link to='#' className='value'>Terms &amp; Conditions</Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default Footer;