import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Service from './pages/Service'
import Projects from './pages/Projects'
import Project from './pages/Project'
import Contact from './pages/Contact'

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollIcon from './components/ScrollIcon';

import { useDispatch } from 'react-redux'
import { setContact } from './redux/slice'
import api from './utils/api';


import Loader from './components/Loader';
import { useLoading } from "./context/LoadingContext";

import { useLocation } from 'react-router-dom';

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loading, updateLoading } = useLoading();

  useEffect(() => {
    updateLoading(true);
  }, [pathname])

  useEffect(() => {
    api.get('/contacts/1').then(res => dispatch(setContact(res.data.data.attributes)))
  }, [dispatch])

  return (
    <>
      { loading && <Loader /> }
      <Header />
      <Routes>
          <Route index element={ <Home /> } />
          <Route path="about" element={ <About /> } />
          <Route path="services" element={ <Services /> } />
          <Route path="services/:service_id" element={ <Service /> } />
          <Route path="projects" element={ <Projects /> } />
          <Route path="projects/:project_id" element={<Project />} />
          <Route path="contact" element={ <Contact /> } />
      </Routes>
      <ScrollIcon />
      <Footer />
    </>
  );
}

export default App;
