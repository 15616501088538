import React, { useEffect, useState } from 'react';
import qs from 'qs';
import SecondaryBanner from '../components/SecondaryBanner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Heading from '../components/Heading'
import Member from '../components/About/Member'
import api from '../utils/api'
import { API_URL } from '../utils/consts'

import { useLoading } from "../context/LoadingContext";


function About(props) {
  const { updateLoading } = useLoading();
  const [teams, setTeams] = useState();
  const [about, setAbout] = useState({});

  useEffect(() => {
    const query = qs.stringify({
      populate: '*'
    }, { encodeValuesOnly: true })
    const query2 = qs.stringify({
      populate: '*'
    }, { encodeValuesOnly: true })

    Promise.all([
      api.get(`/abouts/1?${ query }`),
      api.get(`/teams?${ query2 }`)
    ]).then((values) => {
      setAbout(values[0].data.data)
      setTeams(values[1].data.data)
      updateLoading(false);
    });
  }, [])

  return (
    <>
      <SecondaryBanner />
      <Container className='my-5'>
        <Row className='flex-wrap'>
          <Col sm md={12} lg='6'>
            <Heading white_txt="About" orange_txt="beta" />
            <div className='text-prewrap'>
              { about?.attributes?.description }
            </div>
          </Col>
          <Col sm md={12} lg='6'>
            <div className='position-relative about-image'>
              <div className='position-relative image-container'>
                <img src={ API_URL + about?.attributes?.image?.data?.attributes?.url } alt="About us" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className='mt-5'>
        <Heading white_txt="Our" orange_txt="team" />
        <Row className='flex-wrap services about-us'>
          { teams && teams.map((item,key) => ( 
            <Member key={ key } item={ item } />
          ))}
        </Row>
      </Container>
    </>
  );
}

export default About;