import React, { useEffect, useState } from 'react';
import CircleIcon from './CircleIcon'

function ScrollIcon(props) {
  const [opacity, setOpacity] = useState(0);   

  const handleChange = () => {
    if(typeof window !== 'undefined')
      setOpacity(Math.min(100, Math.max(0, window.pageYOffset - 50)) / 100);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleChange);
    return () => {
      window.removeEventListener("scroll", handleChange);
    };
  }, []);

  return (
    <div 
      className='scroll-icon' 
      style={{ 'opacity': opacity }}
      onClick={ () => window.scrollTo(0, 0) }
    >
      <CircleIcon link='#' className='bx bx-chevron-up' />
    </div>
  );
}

export default ScrollIcon;