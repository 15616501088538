import React, { useEffect, useState } from 'react';
import Banner from '../components/Home/Banner'
import OurServices from '../components/Services/OurServices'
import Featured from '../components/Home/Featured';
import qs from 'qs';
import api from '../utils/api'
import { useLoading } from "../context/LoadingContext";

function Home(props) {
  const [banners, setBanners] = useState();
  const [images, setImages] = useState();
  const { updateLoading } = useLoading();

  useEffect(() => {
    const query = qs.stringify({
      populate: '*',
      sort: ['order_nr:asc']
    }, { encodeValuesOnly: true });

    const query2 = qs.stringify({
      populate: '*'
    }, { encodeValuesOnly: true });
    
    Promise.all([
      api.get(`/banners?${query}`),
      api.get(`/featureds?${query2}`)
    ]).then((values) => {
      setBanners(values[0].data.data)
      setImages(values[1].data.data[0].attributes)
      updateLoading(false);
    });
  }, []);

  return (
    <>
      <Banner banners={ banners } />
      <OurServices show={3} />
      <Featured images={ images } />
    </>
  );
}

export default Home;