import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container';
import CircleIcon from '../CircleIcon'
import { Link } from 'react-router-dom' 
import { API_URL } from '../../utils/consts'
import { useSelector } from 'react-redux'
import Loader from '../Loader'

function Banner({ banners }) {
  const contact = useSelector(state => state.contact?.payload)

  return !contact ? <Loader /> : (
    <Container fluid className='p-0 vh-100'>
      <div className='w-100 h-100 d-flex'>
        <div className="left-side">
          <div className='d-flex flex-column position-relative socials-list'>
            <CircleIcon link={ contact.facebook_url } className='bx bxl-facebook' />
            <CircleIcon link={ contact.instagram_url } className='bx bxl-instagram' />
          </div>
        </div>
        <div className="right-side">
          <div className='right-top-corner'></div>
          <Carousel 
            fade 
            interval={10000} 
            indicators={false} 
            className="carousel-backdrop"
            prevIcon={<i className="bx bx-chevron-left"></i>}
            nextIcon={<i className="bx bx-chevron-right"></i>}
          >
            { banners && banners.map((item, key) => (
              <Carousel.Item key={key} className="image-backdrop">
                <img
                  className="d-block w-100 vh-100"
                  src={ API_URL + item.attributes.image.data.attributes.url }
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>{ item.attributes.title }</h1>
                  <p className='text-prewrap'>{ item.attributes.description }</p>
                  <div className='contact-us-wrapper'>
                    <Link to='/contact' className='contact-us'>Contact us</Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className='right-bottom-corner'></div>
        </div>
      </div>
    </Container>
  );
}

export default Banner;