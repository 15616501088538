import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  var start = null;

  const updateLoading = (value) => {
    if(value === true){
      start = Date.now();
      setLoading(value)
    } else if (value === false){
      const milli = start ? Math.abs(start - Date.now()) : 1000;
      if(milli < 1500){
        setTimeout(() => setLoading(value), 1500 - milli)
      } else {
        setLoading(value)
      }
    }
  }

  const value = { loading, updateLoading };
  return (
    <LoadingContext.Provider value={value}>
      { children }
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}