import qs from 'qs';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Service from './Service';
import Heading from '../Heading'
import api from '../../utils/api'
import { useLoading } from "../../context/LoadingContext";

function OurServices({ show }) {
  const { updateLoading } = useLoading();
  const [services, setServices] = useState([]);

  useEffect(() => {
    const query = qs.stringify({
      pagination: {
        start: 0,
        limit: show,
      },
      populate: '*',
      sort: ['order_nr:asc']
    }, {
      encodeValuesOnly: true,
    });
    api.get(`/services?${query}`).then(res => {
      setServices(res.data.data)
      updateLoading(false);
    })
  }, [show]);

  return (
    <Container className='mt-5'>
      <Heading white_txt="Our" orange_txt="services" />
      <Row className='flex-wrap services'>
        { services.map((item,key) => ( 
          <Service key={ key } item={ item } number={ key+1 } />
        ))}
      </Row>
    </Container>
  );
}

export default OurServices;