import React from 'react';
import { Link } from 'react-router-dom'

function CircleIcon({ link = '#', className }) {
  return (
    <>
      { link === '' || link === '#' ? 
        <Link to={ link } className="circle-icon">
          <i className={ className }></i>
        </Link>
        :
        <a href={ link } className="circle-icon" target='_blank' rel="noreferrer">
          <i className={ className }></i>
        </a>
      }
    </>
  );
}

export default CircleIcon;