import React from 'react';

function Heading({ white_txt, orange_txt }) {
  return (
    <div className='heading'>
      <h1 className='section-title'>{ white_txt } <span>{ orange_txt }</span></h1>
    </div>
  );
}

export default Heading;