import React from 'react';
import Row from 'react-bootstrap/Row';

function Info({ label, value }) {
  return (
    <Row>
      <p><span>{ label }:</span>{ value }</p>
    </Row>
  );
}

export default Info;