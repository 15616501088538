import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'app',
  initialState: {
    contact: null
  },
  reducers: {
    setContact: (state, action) => {
      state.contact = action
    }
  },
})

export const { setContact } = slice.actions

export default slice.reducer