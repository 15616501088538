import React from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'
import { API_URL } from '../../utils/consts'

function Service({ item, number }) {
  const { id, attributes } = item;
  return (
    <>
      <Col sm lg="4" md={ 6 }>
        <Link to={`/services/${ id }`} className='item d-block'>
          <img src={ API_URL + attributes.image.data.attributes.url } alt={ attributes.name } />
          <h5>{ attributes.name }</h5>
          <div className="sep-line"></div>
          <p>{ attributes.short_desc }</p>
          <div className='number'>{`0${ number }`}</div>
        </Link>
      </Col>
    </>
  );
}

export default Service;