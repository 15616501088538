import React from 'react';

function Loader() {
  return (
    <div className={`loader`}>
      <div className='line'></div>
    </div>
  );
}

export default Loader;