import React from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { API_URL } from '../../utils/consts';
import join from '../../utils/join';

function Project({ item }) {
  const { id, attributes } = item;
  return (
    <Col sm md={12} lg="6">
      <div className='item'>
        <img src={ API_URL + attributes.image.data.attributes.url } alt="Project 1" />
        <div className='info'>
          <h6>{ join(attributes.categories.data) }</h6>
          <h5>{ attributes.name }</h5>
          <div className='sep-line'></div>
          <Link to={`/projects/${ id }`}><i className='bx bx-right-arrow-alt'></i></Link>
        </div>
      </div>
    </Col>
  );
}

export default Project;